import * as React from "react";
import SellNoInfoContainer from "../containers/sell-no-info";
import DefaultLayout from "../layouts/default";
export default () => {

  return (
    <>
      <DefaultLayout animatedHeader title="Do Not Sell My Personal Info">
        <SellNoInfoContainer />
      </DefaultLayout>
    </>
  );
};
